<template>

  <div class="container
              page-container
              is-flex
              is-flex-wrap-wrap
              is-justify-content-center
              is-align-items-center">

    <div style="width: 35%; min-width: 300px;">

      <div class="mb-6">
        <p class="has-text-grey
                  has-text-centered
                  is-size-5
                  is-size-6-mobile mb-1">
          Vérifier mon compte
        </p>
      </div>

      <b-notification
        type="is-dark"
        aria-close-label="Fermer message d'erreur"
        role="alert"
        closable
        class="mt-4"
        v-model="active">
        {{ error }}
      </b-notification>

      <b-notification
        type="is-success"
        aria-close-label="Fermer message d'erreur"
        role="alert"
        :closable="false"
        class="mt-4"
        has-icon
        v-model="showSuccessMessage">
        <p>Compte vérifié !</p>
        <p>Vous pouvez maintenant commencer à utiliser Cookie.menu.</p>
      </b-notification>

      <b-button type="is-primary"
                tag="router-link"
                expanded
                to="/signin"
                v-if="showSuccessMessage"
                icon-left="chevron-left">
        Retourner à la page d'authentification
      </b-button>

    </div>

  </div>

</template>

<script>
export default {
  data: () => ({
    showSuccessMessage: false,
    error: null,
    active: false,
  }),
  created() {
    this.verifyAccount();
  },
  methods: {
    async verifyAccount() {
      const loading = this.$buefy.loading.open({
        container: null,
      });

      const { token } = this.$route.query;
      const payload = { token };

      this.active = false;
      try {
        await this.$http.post(`${process.env.VUE_APP_API_URL}/auth/account/verification`, payload);
        this.showSuccessMessage = true;
      } catch (err) {
        const { status } = err.response;
        let message = '';

        switch (status) {
          default:
            message = 'Votre lien de vérification a probablement expiré.';
        }
        this.error = message;
        this.active = true;
      } finally { loading.close(); }
    },
  },
};
</script>

<style scoped lang="scss">
  .page-container {
    min-height: calc(100vh - 162px);
  }
  ::v-deep label {
    font-weight: 400 !important;
  }
</style>